import PrimeVue from "primevue/config";
import { CLUB_PLOC, EVENT_PLOC, MEMBERSHIP_PLOC, REGISTRATION_PLOC } from "@/utils";
import { configureApi, dependenciesLocator } from "neos-core";
import { App } from "vue";

export * as Components from "./components/indexClient";

export const configureApp = (app: App) => {
	app.use(PrimeVue); /*.use(router)*/
	app.provide(EVENT_PLOC, dependenciesLocator.provideEventPloc());
	app.provide(CLUB_PLOC, dependenciesLocator.provideClubPloc());
	app.provide(REGISTRATION_PLOC, dependenciesLocator.provideActivityRegistrationPloc());
	app.provide(MEMBERSHIP_PLOC, dependenciesLocator.provideMemberShipPloc());
};
const BASE_URL = String(import.meta.env.VITE_BASE_URL);

configureApi(BASE_URL, {
	"Content-Type": "application/json",
	Accept: "application/json",
	"Access-Control-Allow-Origin": "*",
	"Access-Control-Allow-Methods": "GET, HEAD, POST, PUT, DELETE, OPTIONS",
	"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept, Z-Key",
});
export * as Vue from "vue";
export { renderToString } from "vue/server-renderer";
