<script setup lang="ts">
import { ComponentSize } from '@/entities';
import { IconVue } from '..';
import { ref } from 'vue';
import Calendar from 'primevue/calendar';

let props = defineProps<{
    themeOptions: {[key:string]: string, required: false, default: null} ;
	regionOptions: {[string], required:false, default: null};
}>();

defineEmits<{
    (e: "themesFilter-changed", filters: string[]): void;
    (e: "regionsFilter-changed", filters: string[]): void;
    (e: "audienceFilter-changed", filters: string): void;
    (e: "frequencyFilter-changed", filters: string[]): void;
    (e: "dateFilter-changed", filter: any): void;
}>();

const themesFilter = ref([]);
const regionFilter = ref([]);

const targetAudienceFilter = ref([]);
const targetAudiences = ["Voor iedereen", "Voor alle Neos leden", "Voor Neos leden van de eigen afdeling"]

const frequencyFilter = ref([]);
const frequencyOptions = ["Eenmalig", "Wederkerend"]

const date = ref();
</script>
<template>
    <div class="c-filter">
        <h3 class="c-filter__header typo-h6">Filteren</h3>
        <article class="c-collapse c-filter__item js-collapse vp5:is-open vp5:ml-3">
            <button class="c-collapse__header font-semibold text-4">
                Thema
                <IconVue
                    :icon="{ size: ComponentSize.Small, classes: 'c-collapse__icon', src: '/assets/icons/caret-down.svg' }" />
            </button>
            <div class="c-collapse__content">
                <div class="c-collapse__container">
                    <div class="c-checkbox-group">
                        <div class="c-checkbox-group__items">
                            <div class="c-checkbox-group__item" v-for="(value, key) in themeOptions" :key="key">
                                <input class="c-checkbox-group__input" type="checkbox" :id="key.toString()" :name="key.toString()"
                                    :value="key" v-model="themesFilter"
                                    @change="$emit('themesFilter-changed', themesFilter)">
                                <label class="c-checkbox-group__label" :for="key.toString()">
                                    <span class="c-checkbox-group__icon"></span>

                                    <span>
                                        {{ value }}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>

        <article class="c-collapse c-filter__item js-collapse vp5:is-open vp5:ml-3">
            <button class="c-collapse__header font-semibold text-4">
                Wanneer
                <IconVue
                    :icon="{ size: ComponentSize.Small, classes: 'c-collapse__icon', src: '/assets/icons/caret-down.svg' }" />
            </button>
            <div class="c-collapse__content">
                <div class="c-collapse__container c-calendar">
					<Calendar v-model="date" selection-mode="range" inline date-format="dd/mm/yy" @update:model-value="$emit('dateFilter-changed', date)"/>
                </div>
            </div>
        </article>


        <article v-if="regionOptions !== null && regionOptions.length > 0" class="c-collapse c-filter__item js-collapse vp5:is-open vp5:ml-3">
            <button class="c-collapse__header font-semibold text-4">
                Regio
                <IconVue
                    :icon="{ size: ComponentSize.Small, classes: 'c-collapse__icon', src: '/assets/icons/caret-down.svg' }" />
            </button>
            <div class="c-collapse__content">
                <div class="c-collapse__container">
                    <div class="c-checkbox-group">
                        <div class="c-checkbox-group__items">
                            <div class="c-checkbox-group__item" v-for="regionOption in regionOptions">
                                <input class="c-checkbox-group__input" type="checkbox" :id="regionOption"
                                    :name="regionOption" :value="regionOption" v-model="regionFilter"
                                    @change="$emit('regionsFilter-changed', regionFilter)">
                                <label class="c-checkbox-group__label" :for="regionOption">
                                    <span class="c-checkbox-group__icon"></span>

                                    <span>
                                        {{ regionOption }}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>

        <article class="c-collapse c-filter__item js-collapse vp5:is-open vp5:ml-3">
            <button class="c-collapse__header font-semibold text-4">
                Voor wie
                <IconVue
                    :icon="{ size: ComponentSize.Small, classes: 'c-collapse__icon', src: '/assets/icons/caret-down.svg' }" />
            </button>

            <div class="c-collapse__content">
                <div class="c-collapse__container">
                    <div class="c-checkbox-group">
                        <div class="c-checkbox-group__items">
                            <div class="c-checkbox-group__item" v-for="targetAudience in targetAudiences">
                                <input class="c-checkbox-group__input" type="checkbox" :id="targetAudience"
                                    :name="targetAudience" :value="targetAudience" v-model="targetAudienceFilter"
                                    @change="$emit('audienceFilter-changed', targetAudienceFilter)">
                                <label class="c-checkbox-group__label" :for="targetAudience">
                                    <span class="c-checkbox-group__icon"></span>

                                    <span>
                                        {{ targetAudience }}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>

        <article class="c-collapse c-filter__item js-collapse vp5:is-open vp5:ml-3">
            <button class="c-collapse__header font-semibold text-4">
                Frequentie
                <IconVue
                    :icon="{ size: ComponentSize.Small, classes: 'c-collapse__icon', src: '/assets/icons/caret-down.svg' }" />
            </button>

            <div class="c-collapse__content">
                <div class="c-collapse__container">
                    <div class="c-checkbox-group">
                        <div class="c-checkbox-group__items">
                            <div class="c-checkbox-group__item" v-for="frequency in frequencyOptions">
                                <input class="c-checkbox-group__input" type="checkbox" :id="frequency" :name="frequency"
                                    :value="frequency" v-model="frequencyFilter"
                                    @change="$emit('frequencyFilter-changed', frequencyFilter)">
                                <label class="c-checkbox-group__label" :for="frequency">
                                    <span class="c-checkbox-group__icon"></span>

                                    <span>
                                        {{ frequency }}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    </div>
</template>
